import React from "react";
import { our_team } from "../../constants";
import { newImages2 } from "../../constants";

export default function TeamSection () {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col-reverse border-b border-neutral-300">
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src={newImages2[0].img} className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]"/>
            </div>
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Our Team</h3>
                {
                    our_team.map((details, index) => (
                        <h2 key = {details.id} className="font-poppins font-bold md:text-[40px] xs:text-[35px] text-[25px] ">{details.catchphrase}</h2>
                    ))
                }
                <div className="bg-green-100 rounded-md p-4">
                    {
                        our_team.map((details, index) => (
                            <div>
                                <h3>{details.statement}</h3>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}