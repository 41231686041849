import React, { useRef } from "react";
import { contact, newContact } from '../../constants'
import *  as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as HiIcons from "react-icons/hi"
import emailjs from '@emailjs/browser';
import * as FaIcons from "react-icons/fa"

export default function ContactSection () {
    const [mail, setMail] = React.useState ({
        name: "",
        email: "",
        subject: "",
        message: ""
    })

    const form = useRef()

    function updateValues(event) {
        const {name, value} = event.target
        setMail (
            prevData => {
                return {
                    ...prevData,
                    [name] : value
                }
            }
        )
    }

    const [emptyFields, setEmpty] = React.useState([])
    const [submittedEmpty, setSubmittedEmpty] = React.useState([])
    const [correctEntry, setCorrectEntry] = React.useState(false)

    function checkEmpty (){
        let empty = []
        if (mail.name === "") {
            empty.push("name")
        }
        if (mail.email === "") {
            empty.push("email")
        }
        if (mail.subject === "") {
            empty.push("subject")
        }
        if (mail.message === "") {
            empty.push("message")
        }
        setEmpty(empty)
    }

    function checkCred() {
        setSubmittedEmpty(emptyFields)
        if (emptyFields.length === 0) {
            setCorrectEntry(true)
        }
    }
    function completeMail() {
        setMail({
            name: "",
            email: "",
            message: ""
        })
        setCorrectEntry(false)
    }

    React.useEffect(
        ()=> {
            checkEmpty()
            console.log(form.current)
        }, [mail]
    )
     
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6190prf', 'template_q40n5vl', form.current, 'ci_DISlTH6Qzb_hm4')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        completeMail() 
        e.target.reset()
    
    }

    const styles = {
        border : "1px",
        borderColor: "red",
        borderStyle: "solid",
        marginBottom: "-1px"
    }

    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300"> 
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Contact Us</h3>
                <div className="bg-green-100 rounded-md p-4 w-full">
                {/* {
                    contact.map((contact, index) => (
                        <div className="p-1 flex items-center justify-center" key = {index}>
                            <IoIcons.IoMdCall style={{color: "black"}} className="md:w-[40px] md:h-[40px] w-[20px] h-[20px]"/>
                            <a className="ml-2 font-poppins
                            font-semibold cursor-pointer md:hover:font-bold" href = {`tel:${contact.number}`}>{contact.number}</a>
                        </div>
                    ))
                } */}
                <div>
                    <a href="https://wa.me/254765204349" className="p-1 flex items-center justify-center cursor-pointer xs:flex-row flex-col">
                        <FaIcons.FaWhatsapp style={{color: "black"}} className="md:w-[40px] md:h-[40px] w-[20px] h-[20px] " />
                            <h4 className={`ml-2 font-poppins sm:text-[18px] xs:text-[15px] text-[13px]
                            font-semibold md:hover:font-bold`}>
                                Chat on Whatsapp
                        </h4>
                    </a>
                </div>
                {
                    newContact.map((newCont, index) => (
                        newCont.id === "email" &&
                        <div className="p-1 flex items-center justify-center xs:flex-row flex-col" key = {index}>
                            <HiIcons.HiOutlineMail style={{color: "black"}} className="md:w-[40px] md:h-[40px] w-[20px] h-[20px]" />
                            <h4 className={`ml-2 font-poppins sm:text-[18px] xs:text-[15px] text-[13px]
                            font-semibold cursor-pointer hover:font-bold`}>{newCont.title}</h4>
                        </div>
                    ))
                }
                </div>
            </div>
            
            <div className="flex-1 flex-col justify-center items-center md:py-0 py-4 sm:py-8 border border-neutral-300 rounded-md md:mt-0 mt-[20px]">
                <h3 className="p-3">We would love to hear from you. Send a message if you would like to get in touch with us or have an inquiry.</h3>
                <form ref={form} onSubmit={sendEmail} className="px-5 flex-col items-center justify-center">
                    <div className="inputbox">
                        <input 
                            type = "text"
                            // placeholder="Name"
                            name = "name"
                            value={mail.name}
                            className="border border-neutral-300 rounded-md mb-3"
                            required
                            onChange={updateValues}
                            style = {(submittedEmpty.includes("name")) ? styles : null}
                        />
                        <span>Name</span>
                    </div>
                    {
                        (submittedEmpty.includes("name")) && 
                        <div>
                            <span style={{color: "red"}}>Name is required!</span>
                        </div>
                    }

                    {
                        (submittedEmpty.includes("name")) && 
                        <br/>
                    }
                    <div className="inputbox">
                        <input 
                            type = "email"
                            // placeholder="Email"
                            name = "email"
                            value={mail.email}
                            className="border border-neutral-300 rounded-md mb-3"
                            required
                            onChange={updateValues}
                            style = {(submittedEmpty.includes("email")) ? styles : null}
                        />
                        <span>Email</span>
                    </div>
                    {
                        (submittedEmpty.includes("email")) && 
                        <div>
                            <span style={{color: "red"}}>Email is required!</span>
                        </div>
                    }

                    {
                        (submittedEmpty.includes("email")) && 
                        <br/>
                    }
                                        <div className="inputbox">
                        <input 
                            type = "text"
                            // placeholder="Email"
                            name = "subject"
                            value={mail.subject}
                            className="border border-neutral-300 rounded-md mb-3"
                            required
                            onChange={updateValues}
                            style = {(submittedEmpty.includes("subject")) ? styles : null}
                        />
                        <span>Subject</span>
                    </div>
                    {
                        (submittedEmpty.includes("subject")) && 
                        <div>
                            <span style={{color: "red"}}>Subject is required!</span>
                        </div>
                    }

                    {
                        (submittedEmpty.includes("subject")) && 
                        <br/>
                    }
                    <div className="inputbox">
                        <textarea 
                            type = "text"
                            // placeholder="Message"
                            name = "message"
                            value={mail.message}
                            className="border border-neutral-300 rounded-md w-full"
                            required
                            onChange={updateValues}
                            style = {(submittedEmpty.includes("message")) ? styles : null}
                        />
                        <span>Message</span>
                    </div>
                    {
                        (submittedEmpty.includes("message")) && 
                        <div>
                            <span style={{color: "red"}}>Message is required!</span>
                        </div>
                    }

                    {
                        (submittedEmpty.includes("message")) && 
                        <br/>
                    }
                    <button onClick= {checkCred} type="submit" className="p-2 bg-emerald-500 rounded-md mt-[20px] mb-[20px] text-white font-poppins font-semibold md:w-[150px] sm:w-[125px] hover:bg-emerald-600">
                        Submit
                    </button>
                </form>
            </div>

        </div>
    )
}