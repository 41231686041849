import React from "react";
import { our_services, home_services } from "../constants";
import { Images } from "../constants";
import { Link } from "react-router-dom";

export default function Services() {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Our Services</h3>
                {
                    our_services.map((service, index) => (
                        <h2 key = {service.id} className="font-poppins font-bold md:text-[40px] xs:text-[35px] text-[25px]">{service.catchphrase}</h2>
                    ))
                }
                <div className="bg-green-100 rounded-md p-4">
                    {
                        home_services.map((service, index) => (
                            service.statement.map((word, index) => (
                                <div key = {word.id} className="">
                                    <h3>{word.words}</h3>
                                </div>
                            ))
                        ))
                    }
                </div>
                <Link to="/services">
                    <button className="p-3 bg-emerald-500 rounded-md mt-[20px] text-white font-poppins font-semibold md:w-[150px] sm:w-[130px] hover:bg-emerald-600">
                        Our Services
                    </button>
                </Link>
            </div>
        </div>
    )
}