import React from "react";
import { navLinks } from '../constants'
import {close, logo, menu } from '../assets'
import * as HiIcons from "react-icons/hi"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import {Link} from "react-router-dom"
import { useLocation } from "react-router-dom";

export default function Navbar(props) {
    const location = useLocation();

    const [subnav, setSubnav] = React.useState(false)

    function showSubnav () {
        setSubnav(!subnav)
    }

    let savedPosition = 0;

    return (
        <div>

            <nav className="w-full py-4 flex justify-between items-center navbar border-b-2 border-secondary-500">
                <Link to="/">
                    <img src={require('../assets/pvet/mainlogo.jpg')} alt="pvet" className="w-[150px] h-[70px] cursor-pointer" />
                </Link>

                <ul className="list-none md:flex hidden justify-end items-center flex-1">
                    {navLinks.map((nav, index) => (
                        <li
                            key = {nav.id}
                            className={`
                                font-poppins
                                font-normal
                                cursor-pointer
                                text-[16px] ${index === navLinks.length - 1 ? 'mr-0' : 'mr-10'}
                                text-secondary
                                ${nav.id === "contact" && "flex flex-row justify-center items-center"}
                            `}
                        >
                            <Link to={nav.link}>
                                <h4 href = {`#${nav.id}`} className={`
                                    ${location.pathname === nav.link ? "bg-green-200 p-1 rounded-md" : ""}
                                    hover:scale-125
                                    transition duration-150 ease-out hover:ease-in
                                    hover:bg-green-200
                                    hover:rounded-sm
                                    hover:p-1
                                `}>
                                    {nav.title} 
                                </h4>
                            </Link>
                            { 
                                nav.number && 
                                <div className="border rounded-md ml-4 p-1 flex items-center bg-emerald-500 hover:bg-emerald-600 text-white">
                                    <IoIcons.IoMdCall />
                                    <a className="ml-1" href = {`tel:${nav.number}`}>{nav.number}</a>
                                </div>
                            }
                            
                        </li>
                    ))}
                </ul>

                <div className="md:hidden flex flex-1 justify-end items-center cursor-pointer">
                    {
                        !props.toggle ? 
                        <HiIcons.HiMenu 
                            className="w-[32px] h-[32px] object-contain"
                            onClick={props.newMenu} 
                        />
                        :
                        <AiIcons.AiOutlineClose onClick={props.newMenu} className="w-[32px] h-[32px] object-contain"/>
                    }

                </div>            
            </nav>
            
            <div className={`flex top-10 mx-4 my-2 md:hidden
                ${props.toggle ? "translate-x-0" : "translate-x-full opacity-0"} ease-in-out duration-300
            `}>
            {
                props.toggle &&
                <ul className="list-none flex flex-col justify-center flex-1">
                    {
                        navLinks.map((nav, index) => {
                            return(
                            <div>
                                {
                                nav.id != "about-us" ?
                                <Link to={nav.link} onClick={props.newMenu}>
                                <li
                                    key = {nav.id}
                                    className={`
                                        font-poppins
                                        font-normal
                                        cursor-pointer
                                        text-[15px] ${index === navLinks.length - 1 ? 'mr-0' : "mb-4"}
                                        text-secondary
                                        bg-green-100
                                        mb-1
                                        p-3
                                        hover:bg-green-300
                                        flex
                                        justify-between
                                        ${nav.subnav && subnav && "bg-green-300"}
                                    `}
                                    onClick={nav.subnav && showSubnav}
                                >
                                    
                                    <h3>
                                        {nav.title}
                                    </h3>

                                    <div>
                                        {
                                            nav.subnav && subnav 
                                                ? nav.iconOpened
                                                : nav.subnav 
                                                ? nav.iconClosed
                                                : null
                                        }
                                    </div>
                                </li>
                                </Link>
                                :
                                <li
                                    key = {nav.id}
                                    className={`
                                        font-poppins
                                        font-normal
                                        cursor-pointer
                                        text-[15px] ${index === navLinks.length - 1 ? 'mr-0' : "mb-4"}
                                        text-secondary
                                        bg-green-100
                                        mb-1
                                        p-3
                                        hover:bg-green-300
                                        flex
                                        justify-between
                                        ${nav.subnav && subnav && "bg-green-300"}
                                    `}
                                    onClick={nav.subnav && showSubnav}
                                >
                                    
                                    <h3>
                                        {nav.title}
                                    </h3>

                                    <div>
                                        {
                                            nav.subnav && subnav 
                                                ? nav.iconOpened
                                                : nav.subnav 
                                                ? nav.iconClosed
                                                : null
                                        }
                                    </div>
                                </li>
                                }
                                <div>
                                    {
                                        subnav && nav.subnav && nav.subnav.map((item, index) => (
                                            <Link to={nav.link} onClick={props.newMenu}>
                                                <li className={`
                                                    font-poppins
                                                    font-normal
                                                    cursor-pointer
                                                    text-[15px] ${index === nav.subnav.length - 1 && "mb-4"}
                                                    text-secondary
                                                    bg-green-200
                                                    p-3
                                                    hover:bg-green-200
                                                    ${index === nav.subnav.length - 1 ? "" : "mb-1"}
                                                    ${index === 0 && "-mt-3"}
                                                    
                                                `}
                                                onClick={showSubnav}
                                                >
                                                    
                                                        <h3>
                                                            {item.title}
                                                        </h3>
                                                    
                                                </li>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        })
                    }
                </ul>
            }
            </div>
        </div>
    )
}