import React from "react";
import { Vaccination } from "../../constants";
import { chick_pvet } from "../../assets";


export default function ServicesSection2() {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="text-emerald-500 font-poppins font-semibold md:text-[30px] xs:text-[25px] text-[20px]">Vaccination Process</h3>
                <div>
                    {
                        Vaccination.map((item, index) => (
                            index >= 2 &&
                            <div className={`p-4 ${index != item.length-1 || 2 && "mt-4"}`}>
                                <h3 className="font-poppins font-bold">{item.header}</h3>
                                <h4 className="bg-green-100 rounded-md p-4">{item.statement}</h4>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex-1 flex justify-center items-center md:py-0 py-4 sm:py-8 ">
                <img src = {chick_pvet} alt="chick_pvet" className="rounded-full object-cover sm:w-[400px] sm:h-[400px] w-[200px] h-[200px]" />
            </div>
        </div>
    )

}