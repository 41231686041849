import React from "react";
import { navLinks } from "../constants";
import { contact, newContact, socialMedia } from '../constants'
import *  as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as HiIcons from "react-icons/hi"
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa"

export default function Footer() {
    const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear());
    React.useEffect(() => {
        setCurrentYear(new Date().getFullYear());
      }, []);
    
    return (
        <div className="flex-col bg-emerald-500">
            <div className="sm:py-12 py-6 flex xs:flex-row flex-col ">
                {/* <div className="flex md:flex-row flex-col flex-1"> */}
                <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                    <h3 className="text-white border-b border-neutral-300">Quick Links</h3>
                    <div className="flex sm:flex-row flex-col sm:justify-center">
                        <ul className="list-none flex-col items-center justify-center text-start p-3">
                            {navLinks.map((nav, index) => (
                                <li
                                    key = {nav.id}
                                    className={`
                                        font-poppins
                                        font-semibold
                                        cursor-pointer
                                        text-[16px] 
                                        text-white
                                        mt-3
                                    `}
                                >
                                    <Link to={nav.link}>
                                        <h4 href = {`#${nav.id}`} className={`
                                            xl:hover:scale-125
                                            transition duration-150 ease-out hover:ease-in
                                        `}>
                                            {nav.title} 
                                        </h4>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <ul className="list-none flex-col items-center justify-center text-start p-3 md:px-[30px]">
                        { 
                            navLinks.map((nav, index) => (
                                nav.subnav && nav.subnav.map((sub, index) => (
                                    <li
                                    key = {sub.id}
                                    className={`
                                        font-poppins
                                        font-semibold
                                        cursor-pointer
                                        text-[16px] 
                                        text-white
                                        mt-3
                                    `}
                                >
                                    <Link to={nav.link}>
                                        <h4 className={`
                                            xl:hover:scale-125
                                            transition duration-150 ease-out hover:ease-in
                                        `}>
                                            {sub.title} 
                                        </h4>
                                    </Link>
                                </li>
                                ))
                            ))
                        }  
                            <div
                                className={`
                                font-poppins
                                font-semibold
                                cursor-pointer
                                text-[16px] 
                                text-white
                                mt-3
                            `}
                            >
                            <h4 className={`
                                xl:hover:scale-125
                                transition duration-150 ease-out hover:ease-in
                            `}>Blog</h4>
                            </div>
                        </ul>
        
                    </div>

                </div>
                <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                    <h3 className="text-white border-b border-neutral-300">Stay Connected</h3>
                    <div className="flex items-center flex-col">
                    {
                        contact.map((contact, index) => (
                            <div>
                                <a href="https://wa.me/254765204349" className="p-1 flex items-center justify-center cursor-pointer xs:flex-row flex-col">
                                    <FaIcons.FaWhatsapp style={{color: "white"}} className="md:w-[30px] md:h-[30px] w-[20px] h-[20px] " />
                                        <h4 className={`ml-2 font-poppins sm:text-[15px] xs:text-[13px] text-[12px]
                                        font-semibold md:hover:font-bold text-white`}>
                                            Chat on Whatsapp
                                    </h4>
                                </a>
                            </div>
                        ))
                    }
                    {
                        newContact.map((newCont, index) => (
                            <div className={`p-1 flex items-center justify-center                           
                            ${newCont.id != "location" && `md:hover:scale-125
                            transition duration-150 ease-out hover:ease-in`}`} key = {index}
                            >
                                {newCont.icon}
                                <h3 className={`
                                    font-poppins
                                    font-semibold
                                    cursor-pointer
                                    text-white
                                    mt-3
                                    ml-2
                                `}>
                                    {newCont.title}
                                </h3>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
            <div className="sm:px-12 px-6 flex-col items-center py-2">
                <div className="mx-auto w-12/12 border-t border-neutral-300">
                    {/* This div gets a border that doesn't span the entire width */}
                </div>
                <div className="flex sm:flex-row flex-col justify-between sm:items-center ">
                    <div className="flex md:flex-row flex-col">
                        <h3 className={`
                            font-poppins
                            font-semibold
                            cursor-pointer
                            text-[16px] 
                            text-white
                            mt-3
                            mr-3
                        `}
                        >Get In Touch</h3>
                        <div className="flex flex-row mt-3">
                        {
                            socialMedia.map((media, index) => (
                                media.id !== "social-media-3" &&
                                <img
                                    onClick={() => window.open(media.link)}
                                    key = {media.id} 
                                    src={media.icon}
                                    alt={media.id}
                                    className={`
                                        w-[21px] h-[21px] object-contain cursor-pointer
                                        ${index !== socialMedia.length - 1 ? "mr-6" : "mr-0"}
                                    `}
                                />
                            ))
                        }
                        <a href="https://www.youtube.com/">
                            <AiIcons.AiFillYoutube style={{color: "white"}}    
                                className={`
                                    w-[21px] h-[21px] object-contain cursor-pointer
                                `}
                            />
                        </a>
                        </div>
                    </div>
                    <div>
                        <h3 className={`
                            font-poppins
                            font-normal
                            cursor-pointer
                            text-[14px] 
                            text-white
                            mt-3
                        `}
                        >Prunervet Centre Ltd © {currentYear} All Rights Reserved</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}