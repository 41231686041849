import React from "react";
import { Images } from "../constants";
import { contact } from '../constants'
import *  as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import { Link } from "react-router-dom";

export default function AllImages(props) {

    React.useEffect(
        () => {
            if (!props.slideDetails.autoSlide) return
            const slideInterval = setInterval(handleNextImage, props.slideDetails.autoSlideInterval)
            return () => clearInterval(slideInterval)
        }, [] 
    )

    const handlePrevImage = () => {
        props.setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? Images.length - 2 : prevIndex - 1
        );
      };
    
      const handleNextImage = () => {
        props.setCurrentImageIndex((prevIndex) =>
          prevIndex === Images.length - 2 ? 0 : prevIndex + 1
        );
      };
    

    return (
        <div className="relative md:h-[600px] h-[500px] overflow-hidden ">
            <div className="absolute inset-0 flex">
                {Images.map((image, index) => (
                index < 6 && index !==0 ?
                <div
                    key={image.id}
                    className={`w-full h-full object-cover absolute transition-transform ease-out duration-1000 ${
                    index === props.currentImageIndex ? "transform-none opacity-100" : "opacity-0 translate-x-full"}
                    `}
                >
                    <div
                        className={`w-full h-full absolute ${
                        index === props.currentImageIndex ? "bg-black/25" : "bg-black/0"
                        }`}
                    ></div>
                    <img src={image.img} alt={`Image ${index + 1}`} className="w-full h-full object-cover " />
                    {image.words && (
                        <div className={`
                            flex
                            inset-0
                            items-center
                            justify-center
                            transition-opacity
                            duration-500
                            absolute
                            ${
                                index === props.currentImageIndex ? "opacity-100" : "opacity-0"
                            }
                        `}>
                            <h4
                            className={`
                                font-poppins
                                font-bold
                                text-white
                                text-center
                                drop-shadow
                                ${image.id === 1 ? "md:text-[30px] text-[25px]" : "md:[text-[30px] text-[20px]"}
                            `}
                            >
                            {image.words}
                            </h4>
                        </div>

                    )}
                    <div className={`
                        bottom-4
                        left-[50%]
                        text-center
                        transform
                        -translate-x-1/2
                        transition-opacity
                        duration-500
                        absolute
                        ${
                            index === props.currentImageIndex ? "opacity-100" : "opacity-0"
                        }
                    `}>
                    <Link to="/contact">
                        <div className="md:hidden border rounded-md ml-4 p-1 flex items-center bg-white w-[150px] h-[50px]" key = {index}>
                            <IoIcons.IoMdCall style={{color: "#366735", width: "25px", height: "25px"}}/>
                            <h5 className="ml-1 text-emerald-600 text-[17.5px] font-poppins font-bold">Contact Us</h5>
                        </div>
                    </Link>

                    </div>
                </div>
                :
                index === 0 &&
                <div
                    key={image.id}
                    className={`transition-transform ease-out duration-1000 ${
                    index === props.currentImageIndex ? "transform-none opacity-100" : "opacity-0 translate-x-full"}
                    w-full h-full flex justify-center items-center 
                    `}
                >
                    <img src={image.img} alt={`Image ${index + 1}`} className="max-width-100 max-height-100" />
                    <div className={`
                        bottom-4
                        left-[50%]
                        text-center
                        transform
                        -translate-x-1/2
                        transition-opacity
                        duration-500
                        absolute
                        ${
                            index === props.currentImageIndex ? "opacity-100" : "opacity-0"
                        }
                    `}>
                        <Link to="/contact">
                            <div className="md:hidden border rounded-md ml-4 p-1 flex items-center bg-white w-[150px] h-[50px]" key = {index}>
                                <IoIcons.IoMdCall style={{color: "#366735", width: "25px", height: "25px"}}/>
                                <h5 className="ml-1 text-emerald-600 text-[17.5px] font-poppins font-bold">Contact Us</h5>
                            </div>
                        </Link>
                    </div>
                </div>
                ))}
            </div>
            <div className="absolute inset-0 items-center justify-between p-1 hidden md:flex">
                <button className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
                    style={{ top: "50%" }} onClick={handlePrevImage}>
                    <AiIcons.AiOutlineLeft size={40}/>
                </button>
                <button className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
                    style={{ top: "50%" }} onClick={handleNextImage}>
                    <AiIcons.AiOutlineRight size={40}/>
                </button>
            </div>
        </div>
    )
}