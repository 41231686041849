import React from "react";
import { product_types } from "../../constants";

export default function ProductSection2 () {
    return (
        <div className="sm:py-12 py-6 flex md:flex-row flex-col border-b border-neutral-300 w-full">
            <div className="flex-col items-center justify-center text-center flex-1 xl:px-0 sm:px-16 px-6">
                <h3 className="font-poppins font-bold mb-4">Our Product Types</h3>
                <div className="sm:grid sm:grid-cols-2 sm:gap-8">
                {
                    product_types.map((type, index) => (
                        <div key={index} className="sm:mb-0 mb-4 bg-white shadow-lg rounded-lg p-4 transition transform hover:-translate-y-1 hover:shadow-xl hover:cursor-pointer">
                            <img src={type.image} alt={type.name} className="w-full sm:h-64 object-contain mb-4" />
                            <h3 className="text-lg font-semibold text-gray-800">{type.name}</h3>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}