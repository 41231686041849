import React from "react";
import TeamSection from "../PageSections/TeamSection";

export default function TeamPage () {
    return (
        <>
            <div className="sm:px-16 px-6 flex justify-center items-start pt-16 md:mt-[20px] mt-[35px]">
                <div className="xl:max-w-[1280px] w-full">
                    <TeamSection />
                </div>
            </div>
        </>
    )
}